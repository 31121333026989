import { z } from "zod";

export const passwordResetSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Can't be blank" }),
});

export const signInFormSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Can't be blank" }),
  password: z.string().min(1, { message: "Can't be blank" }),
});

export const signUpFormSchema = z
  .object({
    firstName: z.string().min(1, { message: "Can't be blank" }),
    lastName: z.string(),
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(8, { message: "Must be at least 8 characters long" }),
    passwordConfirmation: z.string().min(8, { message: "Must be at least 8 characters long" }),
    privacyNoticeAccepted: z.literal(true, {
      errorMap: () => ({ message: "You must read and accept the privacy notice" }),
    }),
    termsAndConditionsAccepted: z.literal(true, {
      errorMap: () => ({ message: "You must read and accept the customer terms and conditions" }),
    }),
  })
  .superRefine(({ passwordConfirmation, password }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: "custom",
        path: ["passwordConfirmation"],
        message: "Passwords do not match",
      });
    }
  });

export type SignInFormRequest = z.infer<typeof signInFormSchema>;
export type SignUpFormRequest = z.infer<typeof signUpFormSchema>;
