"use client";

import {
  Anchor,
  Button,
  Card,
  Divider,
  MantineProvider,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import Link from "next/link";
import { producerTheme } from "~/app/producer/theme";
import { GoogleSignIn } from "~/components/auth/GoogleSignIn";
import { signInFormSchema } from "../../schemas";
import {
  refreshSession,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "~/services/auth.service";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { modals } from "@mantine/modals";
import { checkUserVerification } from "~/requests/users";
import { User } from "firebase/auth";
import { useUser } from "reactfire";
import { useImpersonation } from "~/lib/hooks/impersonation";

const refreshUserSession = async (user: User) => {
  const token = await user.getIdToken();
  return await refreshSession(token);
};

export const SignInForm = () => {
  const searchParams = useSearchParams();
  const { signOutWithoutRedirect } = useImpersonation();
  const { data: user } = useUser();
  const redirect = searchParams?.get("redirect") ?? undefined;
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      password: "",
    },
    validate: zodResolver(signInFormSchema),
  });

  const [unverifiedAccessAttempts, setUnverifiedAccessAttempts] = useState(0);
  const [sendingEmailVerification, setSendingEmailVerification] = useState(false);

  useEffect(() => {
    if (user && !isLoading) {
      setLoading(true);
      refreshUserSession(user)
        .then((result) => {
          return result.ok
            ? window.location.assign(redirect ?? "/")
            : console.error("Failed to refresh user session", result);
        })
        .catch(() => signOutWithoutRedirect().finally(() => setLoading(false)));
    }
  }, [user, isLoading, redirect, signOutWithoutRedirect]);

  const resendVerificationEmail = async (email: string) => {
    setSendingEmailVerification(true);

    try {
      await sendEmailVerification(email.trim());
      setUnverifiedAccessAttempts(0);
      modals.open({
        title: "Email verification",
        centered: true,
        children: (
          <MantineProvider theme={producerTheme} classNamesPrefix="auth" defaultColorScheme="auto">
            <Text>An email has been sent to your address for verification.</Text>
            <Text>
              Please check your inbox and follow the instructions to complete setting up your
              account.
            </Text>
            <Button variant="attention" fullWidth onClick={() => modals.closeAll()} mt="md">
              Okay
            </Button>
          </MantineProvider>
        ),
      });
    } finally {
      setSendingEmailVerification(false);
    }
  };

  const onSignIn = form.onSubmit(async (values) => {
    setLoading(true);

    const verificationCheck = await checkUserVerification(values.email.trim());
    if (verificationCheck.found && !verificationCheck.verified) {
      modals.open({
        title: "Unverified email address",
        centered: true,
        children: (
          <MantineProvider theme={producerTheme} classNamesPrefix="auth" defaultColorScheme="auto">
            <Text>Please verify your email address before signing in.</Text>

            <Stack gap="xs" mt="md">
              {unverifiedAccessAttempts >= 1 && (
                <Button
                  fullWidth
                  onClick={async () => await resendVerificationEmail(values.email)}
                  loading={sendingEmailVerification}
                >
                  Re-send verification email
                </Button>
              )}

              <Button variant="attention" fullWidth onClick={() => modals.closeAll()}>
                Okay
              </Button>
            </Stack>
          </MantineProvider>
        ),
      });
      setLoading(false);
      setUnverifiedAccessAttempts(unverifiedAccessAttempts + 1);
      form.setFieldValue("password", "");
      return;
    }

    await signInWithEmailAndPassword(values.email.trim(), values.password)
      .then(() => window.location.assign(redirect ?? "/"))
      .catch((e) => {
        setLoading(false);
        modals.open({
          title: "Failed to sign in",
          centered: true,
          children: (
            <MantineProvider
              theme={producerTheme}
              classNamesPrefix="auth"
              defaultColorScheme="auto"
            >
              <Text>
                {e.message ??
                  "Something went wrong signing into your account. Please double-check your details and try again."}
              </Text>
              <Button variant="attention" fullWidth onClick={() => modals.closeAll()} mt="md">
                Okay
              </Button>
            </MantineProvider>
          ),
        });
      });
  });

  return (
    <MantineProvider theme={producerTheme} defaultColorScheme="auto" classNamesPrefix="producer">
      <Stack mt="xl" align="center">
        <Card maw={rem(450)} w="100%" p={rem(24)} shadow="sm">
          <Title order={1} size="h2" ta="center" fw={500}>
            Sign in
          </Title>
          <Text c="dimmed" ta="center" size="sm">
            with your kiwiticket account
          </Text>

          <Divider mx={rem(-24)} my={rem(24)} />

          <form onSubmit={onSignIn}>
            <Stack>
              <TextInput
                required
                withAsterisk={false}
                label="Email"
                type="email"
                autoFocus
                {...form.getInputProps("email")}
              />

              <PasswordInput
                required
                withAsterisk={false}
                label="Password"
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? (
                    <IconEyeOff color="var(--mantine-color-text)" />
                  ) : (
                    <IconEye color="var(--mantine-color-text)" />
                  )
                }
                {...form.getInputProps("password")}
              />

              <Button type="submit" variant="attention" loading={isLoading}>
                Sign in
              </Button>

              <Text size="sm" my="xs">
                Trouble signing in?{" "}
                <Anchor
                  component={Link}
                  href={redirect ? `/password-reset?redirect=${redirect}` : "/password-reset"}
                  c="var(--mantine-color-text)"
                >
                  Reset your password
                </Anchor>
              </Text>
            </Stack>
          </form>

          <Divider mx={rem(-24)} my={rem(24)} />

          <Stack align="center">
            <Text c="dimmed" size="sm">
              OR
            </Text>

            <GoogleSignIn />
          </Stack>
        </Card>

        <Text c="dimmed" size="sm" my="xs">
          Don't have an account yet?{" "}
          <Anchor
            component={Link}
            href={redirect ? `sign-up?redirect=${redirect}` : "/sign-up"}
            c="var(--mantine-color-text)"
          >
            Sign up
          </Anchor>
        </Text>
      </Stack>
    </MantineProvider>
  );
};
