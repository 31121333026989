import { useColorScheme } from "@mantine/hooks";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useSearchParams } from "next/navigation";
import { signInWithGoogle } from "~/services/auth.service";

type Props = {
  useOneTap?: boolean;
};

export const GoogleSignIn = ({ useOneTap }: Props) => {
  const colorScheme = useColorScheme();
  const searchParams = useSearchParams();

  return (
    <GoogleLogin
      theme={colorScheme === "dark" ? "filled_black" : "outline"}
      useOneTap={useOneTap}
      onSuccess={async (credentialResponse: CredentialResponse) => {
        await signInWithGoogle(credentialResponse);
        document.location.assign(searchParams?.get("redirect") || "/");
      }}
      onError={() => {
        console.error("failed to sign in with Google");
      }}
    />
  );
};
