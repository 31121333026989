import {
  AppShell,
  Anchor,
  Badge,
  Button,
  TextInput,
  PasswordInput,
  SegmentedControl,
  Input,
  NavLink,
  NumberInput,
  Textarea,
  DEFAULT_THEME,
  createTheme,
  mergeMantineTheme,
  MantineColorsTuple,
  ActionIcon,
  Mark,
  Menu,
  Checkbox,
  Radio,
  Card,
  ColorInput,
  Paper,
  InputWrapper,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { Dropzone } from "@mantine/dropzone";

import appShellClasses from "./AppShell.module.css";
import badgeClasses from "./Badge.module.css";
import buttonClasses from "./Button.module.css";
import cardClasses from "./Card.module.css";
import colorInputClasses from "./ColorInput.module.css";
import dropzoneClasses from "./Dropzone.module.css";
import inputWrapperClasses from "./InputWrapper.module.css";
import modalClasses from "./Modal.module.css";
import markClasses from "./Mark.module.css";
import menuClasses from "./Menu.module.css";
import navLinkClasses from "./NavLink.module.css";
import paperClasses from "./Paper.module.css";
import segmentedControlClasses from "./SegmentedControl.module.css";

const kiwi: MantineColorsTuple = [
  "#f0ffe5",
  "#e3ffce",
  "#c7ff9c",
  "#a9ff65",
  "#8fff3a",
  "#7eff1f",
  "#75ff10",
  "#62e300",
  "#55c900",
  "#44ae00",
];

const lightBadgeColors = ["kiwi", "violet"];

export const producerThemeOverride = createTheme({
  fontFamily: "Montserrat, sans-serif",
  headings: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
  },
  colors: {
    kiwi,
  },
  primaryColor: "dark",
  primaryShade: {
    light: 6,
    dark: 3,
  },
  other: {
    fontWeights: {
      normal: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  fontSizes: {
    xs: "calc(0.875rem * var(--mantine-scale))", // 14px @ 1rem = 16px base
    sm: "calc(0.875rem * var(--mantine-scale))", // 14px @ 1rem = 16px base
    md: "calc(1rem * var(--mantine-scale))", // 16px @ 1rem = 16px base
    lg: "calc(1.125rem * var(--mantine-scale))", // 18px @ 1rem = 16px base
    xl: "calc(1.25rem * var(--mantine-scale))", // 20px @ 1rem = 16px base
  },
  radius: {
    xs: "calc(0.125rem * var(--mantine-scale))", // 2px @ 1rem = 16px base
    sm: "calc(0.25rem * var(--mantine-scale))", // 4px @ 1rem = 16px base
    md: "calc(0.375rem * var(--mantine-scale))", // 6px @ 1rem = 16px base
    lg: "calc(0.5rem * var(--mantine-scale))", // 8px @ 1rem = 16px base
    xl: "calc(0.75rem * var(--mantine-scale))", // 12px @ 1rem = 16px base
  },
  defaultRadius: "md",
  components: {
    ActionIcon: ActionIcon.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: "default",
        size: "lg",
      },
    }),
    AppShell: AppShell.extend({
      classNames: appShellClasses,
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        c: "blue",
        underline: "always",
      },
    }),
    Badge: Badge.extend({
      classNames: badgeClasses,
      vars: (_theme, props) => ({
        root: {
          "--badge-bg":
            props.variant === "light"
              ? `var(--mantine-color-${props.color}-${
                  lightBadgeColors.includes(props.color as string) ? "2" : "3"
                })`
              : undefined,
        },
      }),
      defaultProps: {
        variant: "light",
        size: "md",
        radius: "xl",
      },
    }),
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: "default",
        size: "md",
      },
    }),
    Card: Card.extend({
      classNames: cardClasses,
      defaultProps: {
        withBorder: true,
        radius: "md",
      },
    }),
    ColorInput: ColorInput.extend({
      classNames: colorInputClasses,
    }),
    DateTimePicker: DateTimePicker.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
      },
    }),
    Dropzone: Dropzone.extend({
      classNames: dropzoneClasses,
    }),
    Input: Input.extend({
      defaultProps: {
        variant: "filled",
        size: "md",
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
        inputWrapperOrder: ["label", "input", "error", "description"],
      },
    }),
    Modal: {
      classNames: modalClasses,
      defaultProps: {
        overlayProps: {
          backgroundOpacity: 0.55,
          blur: 3,
        },
      },
    },
    Checkbox: Checkbox.extend({
      defaultProps: { size: "md" },
    }),
    CheckboxGroup: Checkbox.Group.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
        inputWrapperOrder: ["label", "input", "error", "description"],
        errorProps: {
          mt: "0.25rem",
        },
      },
    }),
    RadioGroup: Radio.Group.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
        inputWrapperOrder: ["label", "input", "error", "description"],
        errorProps: {
          mt: "0.25rem",
        },
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      classNames: segmentedControlClasses,
    }),
    InputWrapper: InputWrapper.extend({
      classNames: inputWrapperClasses,
    }),
    TextInput: TextInput.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
        inputWrapperOrder: ["label", "input", "error", "description"],
      },
    }),
    Paper: Paper.extend({
      classNames: paperClasses,
    }),
    PasswordInput: PasswordInput.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
        inputWrapperOrder: ["label", "input", "error", "description"],
      },
    }),
    Textarea: Textarea.extend({
      classNames: inputWrapperClasses,
      defaultProps: {
        variant: "filled",
        size: "md",
      },
    }),
    NavLink: NavLink.extend({
      classNames: navLinkClasses,
      defaultProps: {
        variant: "light",
      },
    }),
    Mark: Mark.extend({
      classNames: markClasses,
      defaultProps: {
        color: "kiwi.2",
      },
    }),
    Menu: Menu.extend({
      classNames: menuClasses,
    }),
  },
});

export const producerTheme = mergeMantineTheme(DEFAULT_THEME, producerThemeOverride);
