import { aussieFunctions } from "~/lib/firebase";
import { httpsCallable } from "firebase/functions";

type KiwiTicketUser = {
  id: string;
  email: string;
  familyName?: string;
  givenName?: string;
  acceptedTermsAndPrivacyNoticeAt?: Date;
  isGuest?: boolean;
};

export const createKiwiTicketUser = async (userDetails: KiwiTicketUser) => {
  const createUserCloudFn = httpsCallable(aussieFunctions(), "createKiwiticketUser");

  await createUserCloudFn({
    userId: userDetails.id,
    email: userDetails.email.trim().toLowerCase(),
    givenName: userDetails.givenName,
    familyName: userDetails.familyName,
    acceptedCustomerTermsAtIso8601: userDetails.acceptedTermsAndPrivacyNoticeAt?.toISOString(),
    acceptedPrivacyNoticeAtIso8601: userDetails.acceptedTermsAndPrivacyNoticeAt?.toISOString(),
    // acceptedPromoterTermsAtIso8601: userDetails.acceptedTermsAndPrivacyNoticeAt?.toISOString(),
    isGuest: userDetails.isGuest ?? false,
  });
};
