import { CredentialResponse } from "@react-oauth/google";
import {
  AuthCredential,
  GoogleAuthProvider,
  UserCredential,
  signInWithCredential as fbSignInWithCredential,
  signInWithEmailAndPassword as fbPasswordSignIn,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { translate } from "react-i18nify";
import { logException } from "~/lib/exceptions";
import { aussieFunctions, auth } from "~/lib/firebase";
import { createKiwiTicketUser } from "~/services/users.service";
import { setUser } from "@sentry/nextjs";

export type UserType = "customer" | "producer" | "both";

export const sendEmailVerification = async (email: string) => {
  const functions = aussieFunctions();
  const sendEmailVerificationCloudFn = httpsCallable(functions, "sendEmailVerification");
  await sendEmailVerificationCloudFn({ emailAddress: email });
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const userCredential = await fbPasswordSignIn(auth(), email.trim(), password.trim());
    await ssrAuth(userCredential);
    setUser({ id: userCredential.user.uid, email: userCredential.user.email ?? undefined });
    return userCredential;
  } catch (error) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const fbError = error as any;
    if (fbError.code === "auth/wrong-password") {
      fbError.message = translate("api.auth.sign_in.invalid_password");
    } else if (fbError.code === "auth/user-not-found") {
      fbError.message = translate("api.auth.sign_in.not_found");
    } else {
      await logException(fbError);
    }
    throw error;
  }
};

export const signInWithGoogle = async (credentialResponse: CredentialResponse) => {
  const credential = GoogleAuthProvider.credential(credentialResponse.credential);
  const userCredential = await signInWithCredential(credential);

  if (userCredential.user) {
    const { email, displayName, uid } = userCredential.user;
    const [givenName, familyName] = (displayName ?? "Google User").split(" ");
    setUser({ id: userCredential.user.uid, email: userCredential.user.email ?? undefined });

    void createKiwiTicketUser({
      id: uid,
      email: email?.trim() ?? "",
      givenName: givenName?.trim(),
      familyName: familyName?.trim() ?? "User",
      acceptedTermsAndPrivacyNoticeAt: new Date(),
    });
  }
};

export const signInWithCredential = async (credential: AuthCredential) => {
  const userCredential = await fbSignInWithCredential(auth(), credential);
  await ssrAuth(userCredential);

  const { user } = userCredential;
  if (user.email) setUser({ id: user.uid, email: user.email });

  return userCredential;
};

export const ssrAuth = async (userCredential: UserCredential) => {
  const idToken = await userCredential.user.getIdToken();
  return await refreshSession(idToken);
};

export const refreshSession = async (idToken: string) => {
  return await fetch("/api/login", {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}` },
  });
};
